<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Préstamos de documentos</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
              <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                  <div class="input-group">
                    <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-outline-primary">Buscar</button>
                      <button
                        type="button"
                        v-on:click="newSearch()"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#searchModal"
                      >Búsqueda avanzada</button>                
                      <search-prestamo v-on:search="searchItems" v-bind:item="search"></search-prestamo>
                    </div>
                  </div>                
                </form>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="table-responsive">
                <table class="table table-sm table-hover table-bordered">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Documentos</td>
                      <td>Fecha</td>
                      <td>Solicitante</td>
                      <td>Descripción</td>
                      <td>Usuario</td>
                      <td>Activo</td>
                      <td>Fecha Vencimiento</td>
                      <td>Fecha Devolución</td>
                      <td>Observaciones</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in items" v-bind:style="{ 'background-color': item.color }">
                      <td class="text-center align-middle">
                        <a href="#" v-on:click.prevent="editItem(item._id)">
                          <i class="far fa-eye" style="font-size:24px"></i>
                        </a>
                      </td>
                      <td class="align-middle">
                        <ol>
                          <li v-for="(doc, j) in item.docs">
                            {{ doc }}
                          </li>
                        </ol>
                      </td>
                      <td class="align-middle">{{ item.prm_fecha | formatDateTime }}</td>
                      <td class="align-middle">{{ item.prm_solicitante }}</td>
                      <td class="align-middle">{{ item.prm_descripcion }}</td>
                      <td class="align-middle">{{ item.usu_nmbre }}</td>
                      <td class="align-middle">
                        {{ item.prm_activo | formatBoolean }}
                      </td>
                      <td class="align-middle">
                        {{ item.prm_fecha_vencimiento | formatDateUtc }}
                      </td>
                      <td class="text-center align-middle">
                        <span v-if="item.prm_activo">
                          <a href="#" v-on:click.prevent="devolucion(index)" title="Devolver documentos">
                            <i class="far fa-calendar-check" style="font-size:24px"></i>
                          </a>
                        </span>
                        <span v-else>{{ item.prm_fecha_devolucion | formatDateTime }}</span>
                      </td>
                      <td class="align-middle">{{ item.prm_observaciones }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br>
            <!--
            <div>
              <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
                <i data-feather="delete"></i>Eliminar
              </button>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Devolución -->
    <div class="modal" id="returnModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Devolución de documentos</h5>
            </div>
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12 text-center">
                  <div class="text-center"><img src="/images/devolucion.png"></div>
                  <br>
                  <div class="text-center"><p>¿Desea registrar la devolución de los documentos?</p></div>
                </div>
              </div>       
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Observaciones:</label>
                  <input type="text" class="form-control" v-model="prm_observaciones">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-primary" v-on:click.prevent="acceptButton" v-bind:disabled="updating">Aceptar</button>
                </div>
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-secondary" v-on:click.prevent="cancelButton" v-bind:disabled="updating">Cancelar</button>
                </div>
              </div>
            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
    </div>
    <!-- End Modal -->       
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import SearchPrestamo from './SearchPrestamo.vue';
import { formatDateTime, formatBoolean, formatDateUtc } from '../../plugins/filters';

export default {
  components: {
    //VIcon,
    SearchPrestamo
  },
  data(){
    return{
      items: [],
      usuarios: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords:'',
      search: {},
      filter: false, // Items are filtered
      selected: null,
      updating: false,
      prm_observaciones: ''
    }
  },
  filters: {
    formatDateTime, formatBoolean, formatDateUtc
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: function()
  {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.searchItems(this.search);
    } else {
      this.search = { active: true };
      this.searchItems(this.search);
    }
  },
  methods: {
    searchItems(p){
      //alert(key);
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
        this.message = 'Buscando préstamos';
        p.cmp_id = this.$store.state.company;
        p.limit = 100;
        let uri = '/prestamos/search';
        this.axios.post(uri, p)
        .then(response => {
          console.log(response);
          var list = response.data;
          this.search = p;
          this.delItems = [];
          this.filter = true;

          let uri = '/usuarios/' + this.$store.state.company;
          this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
          this.axios.get(uri).then(response => {
            this.usuarios = response.data;
            var now = new Date();
            var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

            for (var i = 0; i < list.length; i++) {
              if (!list[i].prm_fecha_devolucion){
                var restante = new Date(list[i].prm_fecha_vencimiento) - today;
  
                if (restante < 0){
                  list[i].color = 'rgba(255, 0, 0, 0.3)'; //red
                } else if (restante == 0){
                  list[i].color = 'rgba(255, 165, 0, 0.3)'; //orange
                } else {
                  //list[i].color = 'rgba(60, 179, 113, 0.3)'; //green
                }
              }

              for (var j = 0; j < this.usuarios.length; j++) {
                if (list[i].usu_id  == this.usuarios[j]._id) {
                  list[i].usu_nmbre = this.usuarios[j].usu_nmbre + " " + this.usuarios[j].usu_aplldo + " (" + this.usuarios[j].usu_cdgo + ")";
                  j = this.usuarios.length;
                }
              }

              let uri = '/docs/basico';
              list[i].docs = [];
              for (var k = 0; k < list[i].prm_docs.length; k++){
                this.axios.post(uri, { id: list[i].prm_docs[k], i: i, k: k })
                .then(response => {
                  list[response.data.i].docs.push(response.data.datos);
                })
                .catch(err => {
                  list[err.data.i].docs.push(err.data.msg);
                });
              }
            }
            this.items = list;
          });
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los préstamos! ' + err;
        });
      }
    },
    closeSearch(){
      this.filter = false;
      this.search = {};
      this.keywords = '';
      this.items = [];
      this.searchItems();
    },
    newSearch () {
      this.search = {};
    },
    editItem(id){
      this.$router.push({ name: 'CreatePrestamo', params: { id: id, add: false, search: this.search } });
    },
    devolucion(i){
      this.selected = i;
      this.prm_observaciones = '';
      $('#returnModal').modal('show');
    },
    acceptButton(){
      var p = {};
      p.id = this.items[this.selected]._id;
      p.prm_observaciones = this.prm_observaciones;
      this.updating = true;

      let uri = '/docs/devolucion';     
      this.axios.post(uri, p)
      .then((response) => {
        this.$set(this.items[this.selected], 'prm_activo', response.data.prm_activo);
        this.$set(this.items[this.selected], 'prm_fecha_devolucion', response.data.prm_fecha_devolucion);
        this.$set(this.items[this.selected], 'prm_observaciones', response.data.prm_observaciones);
        $('#returnModal').modal('hide');
        this.updating = false;
      })
      .catch((err) => {
        alert(err.data.msg);
        $('#returnModal').modal('hide');
        this.updating = false;
      });
    },
    cancelButton(){
      $('#returnModal').modal('hide');
    }
  }
}
</script>